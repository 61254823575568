<script>

export default {
  name: 'authentication-logout',
  render() {
    return false;
  },
  async created() {
    console.log('%c LOGOUT FIRED BOOM', 'font-size:20px; color:red;');
    await localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`);
    await this.$router.push({ name: 'authentication-login' });
  },
};
</script>
